
@media print {

    body {
      background: #FFF;
    }

    .portal {
      .header {
        display: none !important;
      }

      .calendar-navigation {
        display: none;
      }

      .main-container {
        padding: 0px !important;
         .content {
           overflow-y: hidden !important;
           padding: 0px !important;
           .card {
            margin: 0px !important;
            .card-header {
              
              background: #FFF;
              color: #333;
              .print-time {
                  font-size: 16px;
              }
            }
             .card-body {
               padding: 0px !important;
             }
           }
         }
      }

    }

    

}


@media print {

    .calendar {
        padding: 0px !important;
        zoom: 0.91;
    }

}
.calendar {
    padding: 15px;

    .labels {
        min-width: 900px;
        display: flex;
        flex-direction: row;
        border-top: 1px solid #DDD;
        border-bottom: 1px solid #DDD;
        border-left: 1px solid #DDD;
        border-right: 1px solid #DDD;
        .corner {
            flex: 0 0;
            min-width: 80px;
            .week-label {
                text-align: center;
                font-size: 15px;
            }
        }

        .weekdays + .corner {
            border-left: 1px solid #ddd;
            flex: 0 0;
            min-width: 80px;
            .week-label {
                text-align: center;
                font-size: 15px;
            }
        }

        .weekdays {
            flex: 1;
            display: flex;
            flex-direction: row;
            .weekday-label {
                display: block;
                flex: 1;
                text-align: center;
                border-left: 1px solid #DDD;
                font-size: 15px;
                padding: 10px 0px;
                position: relative;
                .weekday-navigation-left {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    display: none;
                    font-size: 30px;
                    
                    a {
                        padding: 10px 20px;
                        display: block;
                    }
                }
                .weekday-navigation-right {
                    display: none;
                    font-size: 30px;
                    top: 0px;
                    
                    position: absolute;
                    right: 0px;
                    cursor: pointer;
                    a {
                        padding: 10px 20px;
                        display: block;
                    }
                }
            }

            // @media only screen and (max-width: 600px) { 
            //     .weekday-label {
            //         display:none;
            //         &.active {
            //             display: block;
            //             .weekday-navigation-left,
            //             .weekday-navigation-right {
            //                 display: block;
            //             }
            //         }
            //     }
            // }

        }
    }

    .days {
        min-width: 900px;
        display: flex;
        flex-direction: row;
        border-left: 1px solid #DDD;
        border-right: 1px solid #DDD;
        .hour-labels {
            flex: 0 0;
            .hour-label {
                font-size: 15px;
                min-width: 80px;
                border-bottom: 1px solid #ddd;
                min-height: 40px;
                box-sizing: border-box;
                padding: 10px;
                max-height: 40px;
                text-align: right;
                &:nth-child(odd) {
                    background: #eeeeee;
                }

                @media print {
                    &:nth-child(odd) {
                        background: #eeeeee;
                    }
                }
            }


        }

        .weekdays + .hour-labels {
            .hour-label {
                text-align: left;
                border-left: 1px solid #ddd;

            }
        }

        .weekdays {
            flex: 1 0;
            display: flex;
            flex-direction: row;
            .weekday {
                position: relative;
                border-left: 1px solid #ddd;
                // border-right: 1px solid #ddd;
                flex: 1;
                .quarter {
                    min-height: 10px;
                    max-height: 10px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #ddd;
                    // &.q1:nth-child(odd),
                    // &.q2:nth-child(odd),
                    // &.q3:nth-child(odd),
                    // &.q4:nth-child(odd) {
                    //     background: #f8f8f8;
                    // }
                    position: relative;
                }

                .event {
                    z-index: 2;
                    position: absolute;
                    width: 80%;
                    vertical-align: middle;
                    display: flex;
                    box-sizing: border-box;
                    border: 1px solid #000;
                    padding: 10px;
                    word-break: break-all;
                    cursor: pointer;
                    > div {
                        flex: 1;
                    }
                    &.overlap {
                        right: 0px;
                        text-align: right;
                        opacity: 0.6;
                        > div {
                            text-align: right
                        }
                    }
                    &:hover {
                        z-index: 3;
                        opacity: 1;
                        border: 3px solid #000;
                    }
                }

                @media print {
                    .quarter:nth-child(8n + 1),
                    .quarter:nth-child(8n + 2),
                    .quarter:nth-child(8n + 3),
                    .quarter:nth-child(8n + 4) {
                        background: #eeeeee !important;
                    }
                }

                .quarter:nth-child(8n + 1),
                .quarter:nth-child(8n + 2),
                .quarter:nth-child(8n + 3),
                .quarter:nth-child(8n + 4) {
                    background: #eeeeee;
                }

            }
            // @media only screen and (max-width: 600px) { 
            //     .weekday {
            //         display:none;
            //         &.active {
            //             display: block;
            //         }
            //     }
            // }
        }

    }


}

// .o-week-table {
//     display: flex;
//     border-top: 1px solid #999;
//     border-bottom: 1px solid #999;
//     .o-week-day {
//         &:first-child {
//             border-left: 1px solid #999;
//         }
//         flex: 1;
//         border-right: 1px solid #999;
//         min-height: 100px;
//         .o-week-day-label {
//             padding: 5px;
//             text-align: center;
//         }

//         .o-week-old-new {
//             display: flex;
//             flex-direction: row;
//             .o-week-old-days {
//                 flex: 1;
//             }
//             .o-week-new-days {
//                 flex: 1;
//                 border-left: 1px solid #999;
//             }
//         }



//         .o-week-day-quarter {
//             // &:nth-child(even) {
//             //     background: #f8f8f8;
//             // }
//             &:hover {
//                 background: rgb(200, 200, 200);
//             }
//             border-top: 1px solid #999;
//             min-height: 10px;
//             max-height: 10px;
//             box-sizing: border-box;
//             cursor: pointer;
//             font-weight: bold;
//             span {
//                 padding: 5px;
//                 text-align: center;
//                 display: block;
//                 max-width: 100%;
//                 color: #000;
//             }
//         }
//         .o-week-day-hour {
//             border-top: 1px solid #999;
//             min-height: 40px;
//             max-height: 40px;
//             box-sizing: border-box;
//             padding: 5px;
//             text-align: center;
//         }
//     }
    

// }