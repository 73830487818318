.checkbox {
    padding: 10px;
    display: flex;
    flex-direction: row;
    input[type=checkbox] {
        flex: 0;
    }
    div {
        flex: 1;
        padding-left: 15px;
    }



}