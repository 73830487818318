@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #edecec;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}
@media print {
  .print-hidden {
    display: none !important;
  }
  .print-visible-inline {
    display: inline !important;
  }
  .print-visible-block {
    display: block !important;
  }
}

.print-visible-inline,
.print-visible-block {
  display: none;
}


.portal {
  display: flex;
  flex-direction: column;

  .header {
    flex: 1;
    height: 70px;
    background-color: #2d0b4a;
    padding: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    h2 {
        padding: 0px;
        margin: 0px;
        color: #FFFFFF;
        font-weight: normal;
        font-size: 20px;
        text-transform: uppercase;
    }
    .menu-toggle-container {
        .menu-toggle {
            cursor: pointer;
            width: 18px;
            height: 12px;
            margin: 12px 20px;
            .menu-toggle-line {
                width: 18px;
                height: 2px;
                background: #FFFFFF;
                transition: all;
                transition-duration: 300ms;
                
                &.center {
                    margin: 3px 0;
                }
                
            }
           
            &.open {
                transform: rotate(180deg);
                .menu-toggle-line.top {
                    width: 12px;
                    -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
                }
                .menu-toggle-line.bottom {
                    width: 12px;
                    -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
                }
            }
        }
    }
    

  }

  .main-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: calc(100vh - 70px);
    .menu-header {
        background: #eee;
        padding: 15px;
        font-size: 16px;
        margin: 20px 0px 0px 0px;
    }
    .inner-menu {
      list-style-type: none;
      padding: 20px 0px;
      margin: 0px;
      li {
        padding: 10px 30px;

        i {
          min-width: 30px;
          font-size: 17px;
        }

        a {
          text-decoration: none;
          color: #4c4c4c;
          font-weight: 700;
          font-size: 13px;
        }

        &:hover {
          background-color: #f7f7f7;
        }
      }
    }

    .content {
      flex: 1;
      width: 100%;
      background: #edecec;
      padding: 10px;
      .card {
        min-height: 100%;
        margin: 10px;
      }
      .card-header {
          padding: 15px;
         
          background: #a30e31;
          color: #fff;
          h1 {
              margin: 0px;
              padding: 0px;
              font-size: 25px;
              font-weight: 300;
          }
      }
      .card-navigation {
          display: flex;
          flex-direction: row;
          @media (max-width: 768px) {
            flex-direction: column;
          }
          a {
            display: block;
            background: white;
            text-align: center;
            text-decoration: none;
            color: #333;
            font-weight: bold;
            flex: 1;
            padding: 15px;
            border-bottom: 5px solid #ccc;
            &.selected {
                border-bottom: 5px solid #2d0b4a;
            }
          }
      }
      .card-body {
        background: #fff;
        
        &.card-padding {
            padding: 15px;
        }
      }
    }
  }

  .btn {
    border: 0;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    border-radius: 2px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.btn-primary {
        color: #fff;
        background-color: #2196F3;
        border-color: #0d8aee;
    }
    &.btn-success {
        color: #fff;
        background-color: #4caf50;
        border-color: #449d48;;
    }
    &.btn-danger {
        color: #FFF;
        background-color: #F44336;
        border-color: #f32c1e;
    }
    &:disabled {
        opacity: 0.7;
    }
}

.pull-right {
    float: right;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


}

@keyframes slide {
  100% {
    left: 0px;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0px;
  }
}
