.settings {

    .card-body {
        padding: 1px 15px 15px 15px;
    
        .settings-block {

            position: relative;
            padding-bottom: 50px;
            

            h2 {
                background: #eee;
                font-weight: 300;
                font-size: 23px;
                padding: 10px;
            }

            h3 {
                font-weight: 300;
                font-size: 19px;
            }

            .btn-success {
                display: block;
                margin-top: 20px;
            }

            .form {
                max-width: 500px;   

                .form-row {
                    display: flex;
                    margin-bottom: 10px;
                    @media (max-width: 768px) {
                        flex-direction: column;
                    }
                   
                    .form-label {
                        flex: 1;
                    }
                    .form-control {
                        flex: 1;
                    }
                    .btn {
                        flex: 0;
                        margin: 0px !important;
                        
                    }
                }
            }
            

        }
    
    }



}