.textarea {
    position: relative;
    .textarea-label {
        position: absolute;
        top: -10px;
        color: #444;
        left: 5px;
        font-size: 14px;
        background: #FFF;
        padding: 2px;
        border: 1px solid #DDD;
    }

    &.even-padding textarea {
        padding: 10px;
    }

    textarea {
        display: block;
        width: 100%;
        padding: 20px 10px;
        font-size: 14px;
        border: 1px solid #ddd;
        box-sizing: border-box;
    }

    textarea:focus {
        border: 1px solid #ddd;
        border-bottom: 2px solid #ccc;
        outline: none;
    }

}