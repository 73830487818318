.input {
    position: relative;
    .input-label {
        position: absolute;
        top: -6px;
        color: #444;
        font-size: 14px;
    }

    &.error input,
    &.error input:focus {
        border-bottom: 2px solid red;
    }

    .error-message { 
        color: red;
    }

    input {
        display: block;
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ddd;
        box-sizing: border-box;
    }

    input:focus {
        border: 1px solid #ddd;
        border-bottom: 2px solid #ccc;
        outline: none;
    }


}