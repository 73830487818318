.infotheek {

    .subjects {
        
        
        .subject {
            &:nth-child(even) {
                background: #f8f8f8;
            }
            .toggle {
                padding: 10px 15px;
                cursor: pointer;
            }
           
        
            &.expanded {
                background: rgba(0, 184, 0, 0.123);
                .toggle {
                    font-weight: bold;
                    background: rgba(0, 184, 0, 0.288);
                }
            }

            .articles {
                
                // border-left: 2px solid #2d0b4a;
                .article {
                    padding: 10px 15px;
                    cursor: pointer;
                }
            }
        
        
        }
    }
    

}
.dialog-backdrop {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0,0,0,0.5);
}
.article-dialog {
    position: fixed;
    @media (min-width: 769px) {
        max-width: 738px;
        width: 738px;
        left: calc(50% - 369px);
        top: 100px;
        max-height: calc(100vh - 130px);
    }
    @media (max-width: 768px) {
        top: 15px;
        bottom: 15px;
        left: 15px;
        right: 15px;
    }
    
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px; 
    display: flex;
    flex-direction: column;

    .article-dialog-header {
        flex: 0;
        padding: 15px;
        background: #f8f8f8;
    }
    .article-dialog-footer {
        flex: 0;
    }
    .article-dialog-body {
        padding: 15px;
        flex: 1;
        overflow-y:scroll;

        .attachments {
            background: #f8f8f8;
            padding: 10px 5px;
            .attachment {
                display: block;
            }
        }
    }

    

}