.calendar-navigation {

    display: flex;
    flex-direction: row;
    
    .navigation-left {
        flex: 0;
        font-size: 30px;
        padding-right: 20px;
    }
    .navigation {
        flex: 1;
    }
    .navigation-right {
        flex: 0;
        font-size: 30px;
        padding-left: 20px;
    }

}