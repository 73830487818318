.bookings {


    .planningrecords {

        .booking {
            h3 { 
                color: #d40024;
                font-size: 15px;
            }
            header { 
                display: flex;
                flex-direction: row;
                background: #2d0b4a;
                color: #fff;
                & > div:first-child {
                    background: #d40024;
                    flex: 0;
                    min-width: 200px;
                }
                & > div {
                    flex: 1 1;
                    padding: 20px;
                }
            }
            section {
                display: flex;
                flex-direction: row;
                & > div:first-child {
                    flex: 5;
                }
                & > div {
                    flex: 7;
                    padding: 15px;
                }

                .times {
                    display: flex;
                    & > div {
                        flex: 1;
                        padding: 5px;
                    }
                }

                .times-reasons {

                    & > div {
                        padding: 5px;
                    }
                }

                .backpayments {

                    .backpayment {
                        margin: 15px;
                        display: flex;
                        
                        .type {
                            flex: 3;
                            padding: 5px;
                        }
                        .description {
                            flex: 6;
                            padding: 5px;
                        }
                        .amount {
                            flex: 3;
                            padding: 5px;
                        }
                    }

                }
            }

            footer {

                display: flex;
                justify-content: flex-end;
                padding: 15px;
                & > div:first-child {
                    min-width: 400px;
                }
                & > div {
                    flex: 0;
                }

            }

            @media (max-width: 768px) {
                header,
                section,
                footer {
                    flex-direction: column;
                }

                section {
                    .backpayments {
                        
                        .backpayment {
                            flex-direction: column;
                            .type, 
                            .description,
                            .amount {
                                flex: 1 1;
                                display: block;
                            }
                        }
                        
                    }
                }
                
                footer {
                    & > div:first-child {
                        min-width: 300px;
                    }
                }

                .btn-cancel-service {
                    word-wrap: break-word;
                    white-space: normal
                }
            }

            
        }


    }


}