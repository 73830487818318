.document-table {


    @media (max-width:768px) {
        .documents-header {
            display: none !important;
        }

        .document-table-row {
            flex-direction: column;
            .document-table-item {
                .document-table-item-label {
                    display: none;
                }
                a {
                    background: #eee;
            
                }
            }
        }
        
    }

    @media (min-width: 768px) {
        .documents-header {
            
            font-weight: bold;
        }

        .document-table-row {
            flex-direction: row;
            // border-bottom: 1px solid #eee;
            .document-table-item {
                padding: 5px;
                &:nth-child(1) {

                }
                &:nth-child(2) {
                    max-width: 15%;
                    // border-left: 1px solid #eee;
                }
                &:nth-child(3) {
                    max-width: 15%;
                    // border-left: 1px solid #eee;
                }
                &:nth-child(4) {
                    // border-left: 1px solid #eee;

                } 
                .document-table-item-label {
                    display: none;
                }
            }
        }
    }

    .document-table-group-header {
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        background: #eee;
    }

    .document-table-row {
        display: flex;
        
        .document-table-item {
            flex: 1;
            display: flex;
            flex-direction: row;
            a {
                display: block;
                padding: 10px;
            }
            .document-table-item-label {
                flex: 0;
                padding-right: 10px;
            }
            .document-table-item-content {
                flex: 1;
                
                ul {
                    padding: 0px;
                    list-style-type: none;
                    margin: 0px;
                    li {
                    }
                }
            }
        }

    }

}