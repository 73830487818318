.clients {

    .week-overview {
        padding: 10px;
        .client {
            background: #eee;
            font-size: 17px;
            font-weight: bold;
            padding: 10px;
            margin: 5px;
            h2 {
                padding: 0px;
                margin: 0px;
                font-weight: 300;
                font-size: 23px;
            }
        }
        .clients-weeks {
    
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            
            .clients-week {
                flex: 1;
                padding: 10px;
                background: green;
                text-align: center;
                color: #FFF;
                margin: 5px;
                max-width: 90px;
                width: 90px;
                min-width: 90px;
                text-decoration: none;
                cursor: pointer;
                &.open {
                    background: red;
                }
            }
    
        };
    
    }
    

}