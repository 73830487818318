.modal-backdrop {

    z-index: 200;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    .modal {
        background: #FFF;
        width: 90%;
        height: 90%;
        max-width: 600px;
        max-height: 400px;
        position: relative;
        padding: 15px;
        display: flex;
        flex-direction: column;
        .modal-body {
            flex: 1;
        }
        .modal-footer {
            flex: 0;
            position: relative;
            bottom: 10px;
            text-align: right;
        }

        .modal-close {
            font-size: 18px;
            position: absolute;
            right: -10px;
            top: -10px;
            background: #f8f8f8;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 20px;
            border: 1px solid #888

        }
    }


}